<script>
import { BIconArrowLeft, BIconEnvelope, BIconEye, BIconEyeFill  } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { required, email, sameAs, minLength,requiredIf } from 'vuelidate/lib/validators';
import firebase from 'firebase/app';
import * as fb from '../firebase';
import { auth,customersCollection,agreementsCollection,usersCollection,firebaseApp,db } from '../firebase';
import { clioLogin } from '../clioIntegration';
import clientUserFormHelper from '@/mixins/clientUserFormHelper';
import allProvinces from 'provinces';
import filter from 'lodash/filter';
import { mapState } from 'vuex';
import toastMessage from '@/mixins/toast';
import emailApiRequest from '@/lib/emailApiRequest';

export default {
    components: {
        BIconArrowLeft,
        BIconEnvelope,
        BIconEye,
        BIconEyeFill
    },
    mixins: [validationMixin, clientUserFormHelper,toastMessage],
    data() {
        return {
            // currentSignUpPage: null,
            // currentLoginPage: null,
            pages: [1, 2, 3],
            currentRole: null,
            activeTab: null,
            connectClio: null,
            forgotPassword: false,
            highestLoginPageVisited:1,
            highestSignupPageVisited:1,
            clientDetails: {
                firstName: '',
                lastName: '',
                organizationName: '',
                locationOfPractice: '',
                email: '',
                phoneNumber: '',
                password: '',
                confirmPassword: '',
            },
            loginDetails: {
                email: '',
                password: ''
            },
            resetEmail:null,
            loginError: null,
            loginLoading:false,
            signupError:null,
            signUpLoading:false,
            resetPasswordError: null,
            resetPasswordLoading: false,
            resetEmailSent: false,
            showPassword: false,
            authProvider : '',
            userSnap:'',
            emailDisabled:false,
            avoidPassword:false,
            showConfirmPassword:false,
            isUserExists:false,
            signupUserId:'',
            agreementId:'',
            isGoBackVisible: true,
        };
    },
    validations: {
        clientDetails: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required,
                email
            },
            phoneNumber: {
                required,
            },
            password: {
                required:requiredIf(function(){
                    return this.authProvider == 'GOOGLE' ? false: true
                }),
                minLength: minLength(6)
            },
            confirmPassword: {
                required:requiredIf(function(){
                    return this.authProvider == 'GOOGLE' ? false: true
                }),
                sameAsPassword: sameAs('password')
            },
        },
        loginDetails: {
            email: {
                required,
                email
            },
            password: {
                required
            }
        },
        resetEmail:{
            required,
            email
        }
    },
    mounted() {
        this.setQueryValuesToPage(this.$route.query);
        // if(this.agreementId){
        //     localStorage.setItem('clientUserAgreement', this.agreementId);
        // }else if(localStorage.getItem('clientUserAgreement')){
        //     this.agreementId = localStorage.getItem('clientUserAgreement');
        // }
        if(localStorage.getItem('showGoogleLoginConfirmation') && localStorage.getItem('showGoogleLoginConfirmation') =='true'){
            this.signInWithGoogle();
            setTimeout(()=> {localStorage.removeItem('showGoogleLoginConfirmation')},1000);
        }
        if (this.activeTab === 0) {
            this.changeloginPage(Number(this.$route.query.page) || 1) 
        }
        else if (this.activeTab === 1) {
            this.changeSignUpPage(Number(this.$route.query.page) || 1)
        };
        if(localStorage.getItem('loginProvider')){
            if((localStorage.getItem('loginProvider')=='CLIO' || localStorage.getItem('isClioFetch') == 'true') && localStorage.getItem('clioUser')) {
                this.clientDetails.email =  JSON.parse(localStorage.getItem('clioUser')).email;
                this.loginDetails.email =  JSON.parse(localStorage.getItem('clioUser')).email;
                this.emailDisabled = true;
            } else if(localStorage.getItem('loginProvider')=='GOOGLE'){
                if(auth.currentUser){
                    // console.log('auth.currentUser',auth.currentUser)
                    this.clientDetails.email =  auth.currentUser.email;
                    this.clientDetails.firstName =  auth.currentUser.displayName;
                    this.setValuesAfterGoogleSignUp(auth.currentUser.email)
                    this.signupUserId = auth.currentUser.uid;
                }
            }
        } else {
                // TO BE DELETED
            // this.$v.loginDetails.email.$model='client300@mailinator.com'
            // this.$v.loginDetails.password.$model='123456'
        }

    },
    computed:{
        Provinces(){
			const provinces = filter(allProvinces, { country: 'CA' });
			return provinces.filter((province)=>province.name !== "Quebec")
		},
        ...mapState(['roleLevels','currentSignUpPageNo','currentLoginPageNo']),
        currentSignUpPage() {
            return this.currentSignUpPageNo; 
        },
        currentLoginPage() {
            // console.log(this.currentLoginPageNo)
            return this.currentLoginPageNo; 
        },
        roles() {
            return {
                lawyer: {
                    label: 'Lawyer',
                    key: 'lawyer',
                    icon: require('@/assets/lawyer.png')
                },
                party: {
                    label: 'Separating Party',
                    key: 'separating-party',
                    icon: require('@/assets/separating-party.png')
                },
                assistant: {
                    label: 'Assistant',
                    key: 'assistant',
                    icon: require('@/assets/assistant.png')
                }
            }
        }
    },
    methods: {
        changeRole(role) {
            this.currentRole = role;
            this.$router.replace({
                query: { ...this.$route.query, role: role },
            }).catch(() => {});

        },
        changeTab(event,selectedTab) {
            return new Promise(async resolve => {
            if(selectedTab === 0){
                // this.currentLoginPage = 1
                // this.$route.query.page = 1
                this.changeloginPage(1);
                this.loginError=''
            }
            if(selectedTab === 1){
                // this.currentSignUpPage = 1
                // this.$route.query.page = 1
                this.changeSignUpPage(selectedTab)
            }
            // if(changeInAuth !=null && changeInAuth!=undefined){
            //     this.$route.query.auth = changeInAuth;
            // }
            // console.log('selectedTab -> ',selectedTab)
            
            this.$store.dispatch('clearConditionalStorageValues');
            this.resetSignUpForm();
            let queryData = { auth: selectedTab,page:1 };
            if(this.$route.query.id)
                queryData = { auth: selectedTab,page:1 ,id:this.$route.query.id}
            resolve(this.$router.replace({
                query: queryData,
            }).catch(() => {}));
            })

        },
        validateState(name, mode) {
            if(mode === 'forgotPassword'){
                const { $dirty, $error } = this.$v["resetEmail"];
                return $dirty ? !$error : null;  
            }
            if (mode === 'login') {
                const { $dirty, $error } = this.$v.loginDetails[name];
                return $dirty ? !$error : null;
            }
            else {
                const { $dirty, $error } = this.$v.clientDetails[name];
                return $dirty ? !$error : null;
            }
        },
        changeSignUpPage(page) {
            // console.log('this.currentSignUpPage->',this.currentSignUpPage , 'page->',page)
            if (this.currentSignUpPage !== page) {
                // this.currentSignUpPage = page;
                this.changeSignUpPageAction(page);
                if (page > this.highestSignupPageVisited) {
                    this.highestSignupPageVisited = page;
                }
                if(this.$route.query.page != page){
                    this.setStaticQueryDataToRouter(page);
                }  
            }
        },
        changeSignUpPageAction(page){
            this.setStaticQueryDataToRouter(page);
            this.$store.dispatch('setCurrentSignUpPageAction',page);
        },
        changeloginPage(page) {
            // console.log('page',page)
            if (this.forgotPassword) {
                this.forgotPassword = false;
            }
            if (this.currentLoginPage !== page) {
                // this.currentLoginPage = page;
                this.changeloginPageAction(page);
                if (page > this.highestLoginPageVisited) {
                    this.highestLoginPageVisited = page;
                }
                // if(this.$route.query.page != page){
                    this.setStaticQueryDataToRouter(page);
                }
                // }   
        },
        changeloginPageAction(page){
            this.setStaticQueryDataToRouter(page);
            this.$store.dispatch('setCurrentLoginPageAction',page);
        },
        setStaticQueryDataToRouter(page){
            let queryData = { ...this.$route.query, page: page };
            if(this.$route.query.id){
                queryData = { ...this.$route.query, page: page ,id: this.$route.query.id}
            }
            this.$router.replace({
                query: queryData,
            }).catch(() => {});
        },
        changeDotPage(page, mode) {
            if(mode ==='login'){
                if (page <= this.highestLoginPageVisited) {
                    this.changeloginPage(page);
                }
            }
            if(mode ==='signup'){
                if (page <= this.highestSignupPageVisited) {
                    this.changeSignUpPage(page);
                }
            }
            
        },
        handleClioCheckbox() {
            this.$router.replace({
                query: { ...this.$route.query, clioFetch: this.connectClio },
            }).catch(() => {});

        },
        async signUpWithClio() {
            await this.setAuthProvider('CLIO');
            // console.log('routeparam', this.$route.query)
            // console.log('process.env.VUE_APP_CLIO_CLIENT_ID', process.env.VUE_APP_CLIO_CLIENT_ID)
            if(this.$route.query)
                localStorage.setItem('redirectRouteParams',JSON.stringify(this.$route.query))
                clioLogin();
        },
        signUpWithGoogle() {
            this.setAuthProvider('GOOGLE');
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: 'select_account'
            });
            auth.signInWithPopup(provider).then(async (googleResponse)=>{  
                // console.log('googleResponse-->',googleResponse) 
                this.userSnap = googleResponse;
                if( this.userSnap &&  this.userSnap.additionalUserInfo){
                    if(this.userSnap.additionalUserInfo.profile) {
                    this.clientDetails.firstName =  this.userSnap.additionalUserInfo.profile.given_name;
                    this.clientDetails.lastName =  this.userSnap.additionalUserInfo.profile.family_name;
                    this.clientDetails.email =  this.userSnap.additionalUserInfo.profile.email;
                    localStorage.setItem('loggedInEmailID',this.userSnap.additionalUserInfo.profile.email);
                    }
                    this.signupUserId = this.userSnap.user.uid;
                } 
                await this.setValuesAfterGoogleSignUp(this.userSnap.additionalUserInfo.profile.email);
                setTimeout(async () => {
                    if(this.$route.query.authProvider && this.$route.query.authProvider =='GOOGLE'){
                        this.isUserExists = true;
                    }
                    this.changeSignUpPage(3);
                    if(this.userSnap?.additionalUserInfo?.isNewUser && this.clientDetails.email)
                        this.sendOnboardingEmail(this.clientDetails.email);
                    // console.log('this.$route.query second->',this.$route.query)
                }, 1000);
                // console.log('this.$route.query first->',this.$route.query)
                // if(this.$route.query && this.$route.query.clioFetch == 'true'){
                //     localStorage.setItem('redirectRouteParams',JSON.stringify(this.$route.query))
                //     clioLogin();
                // } else {

                
                // }
            }).catch((err)=>{
                // console.log(err)
            })
        },
        async setValuesAfterGoogleSignUp(loggedInEmail){
         return new Promise(async (resolve) => {
           this.isUserExists = false;
           let userAlreadySignedUp = await this.getCustomerUsingEmail(loggedInEmail)
            // console.log('userAlreadySignedUp-->',userAlreadySignedUp)
                
            if(Object.keys(userAlreadySignedUp).length){
                this.isUserExists = true;
                // this.infoMessage('User Exists','The user exists already. Please update the details here / proceed to login TAB for user login');
                if(userAlreadySignedUp.displayName)
                    this.clientDetails.firstName = userAlreadySignedUp.displayName
                if(userAlreadySignedUp.lastName)
                    this.clientDetails.lastName = userAlreadySignedUp.lastName;
                if(userAlreadySignedUp.email)
                    this.clientDetails.email = userAlreadySignedUp.email;
                if(userAlreadySignedUp.organizationName)
                    this.clientDetails.organizationName = userAlreadySignedUp.organizationName;
                if(userAlreadySignedUp.phoneNumber)
                    this.clientDetails.phoneNumber = userAlreadySignedUp.phoneNumber;
                if(userAlreadySignedUp.locationOfPractice)
                    this.clientDetails.locationOfPractice = userAlreadySignedUp.locationOfPractice;
            }  
            this.emailDisabled = true; 
            resolve();
            });
        },
        async signUpWithEmail() {
            await this.setAuthProvider('EMAIL');
            this.resetSignUpForm();
            this.changeSignUpPage(3)
        },
        setAuthProvider(provider){
            this.emailDisabled = false;
            return new Promise(resolve => {
                this.authProvider = provider;
                this.$store.commit('setLoginProvider',provider);
                if(this.$route.query.authProvider !== provider){
                    resolve(this.$router.push({
                        query: { ...this.$route.query, authProvider: provider },
                    }).catch(()=>{}))
                } else {
                    resolve(true)
                }
            });
        },
        async saveUserDataToCollection(userAlreadySignedUp){
            return new Promise(async(resolve, reject) => {
            let roleLevel = null 
            if(this.currentRole === 'party'){
                roleLevel = 'client-level-1'
            }
            else if(this.currentRole === 'assistant'){
                roleLevel = 'assistant-level-1'
            } else {
                this.currentRole = 'lawyer';
                roleLevel = 'lawyer-level-1';
            }
            let isSharedUser = false
            // console.log('saveUserDataToCollection role->',roleLevel)
            if((!userAlreadySignedUp || !userAlreadySignedUp.roleLevel) && this.agreementId){
              let rolelevelPerAgreement = await this.getRoleLevelPerAgreementOfCustomer().catch((err)=>{ console.error('er-->',err)});
              if(rolelevelPerAgreement)
                roleLevel = rolelevelPerAgreement
                isSharedUser = true
            }   
            
            // // console.log('this.userSnap.user.uid',this.userSnap.user.uid);
            let userId = (this.userSnap && this.userSnap.user && this.userSnap.user.uid) ? this.userSnap.user.uid : this.signupUserId;
            const dataToSet = {
                displayName:  this.clientDetails.firstName,
                lastName: this.clientDetails.lastName,
                email: this.clientDetails.email,
                organizationName: this.clientDetails.organizationName,
                phoneNumber: this.clientDetails.phoneNumber,
                locationOfPractice: this.clientDetails.locationOfPractice,
                roleLevel: roleLevel,
                roleAssociated:this.currentRole,
                isSharedUser: isSharedUser
            };
            // console.log('dataToSet-->',dataToSet)
            let customerAddUpdate = await this.updateCustomersCollectionWithRetry(dataToSet,userId)
                                    .catch((err)=>{
                                        console.error('err-=-=->>>',err);
                                        reject(err)
                                    });
            // BACKUP CODE of v1.9
            // let customerAddUpdate = await customersCollection.doc(userId).set(dataToSet, { merge: true })
            // .catch((err)=>{
            //     console.error('err-=-=->>>',err);
            //     reject(err)
            // });

            if(this.agreementId){
                // console.log('----',this.agreementId)
                await this.setAgreementDetailsToUserCollection(userId,this.clientDetails.email);
            }


            // console.log('customerAddUpdate',customerAddUpdate);
             resolve(customerAddUpdate)
            });
        },
        async updateCustomersCollectionWithRetry(dataToSet,userId) {
            return new Promise(async (resolve,reject)=>{
                let attempts = 0;
                let success = false;
                const maxAttempts = 3;
                const delayMs = 1000;
                while (attempts < maxAttempts && !success) {
                    try {
                    await db.runTransaction(async (transaction) => {
                        const docSnapshot = await transaction.get(customersCollection.doc(userId));
                        const existingData = docSnapshot.data();
                        const mergedData = { ...existingData, ...dataToSet };
                        transaction.set(customersCollection.doc(userId), mergedData, { merge: true });
                    });
                    success = true;
                    // console.log("Customer updated successfully.");
                    } catch (error) {
                    console.error("Err Customer updation fail:", attempts + 1," attempt->", error);
                    attempts++;
                    await new Promise(resolve => setTimeout(resolve, delayMs)); // Delay before retry
                    }
                }

                if (!success) {
                    console.error(`Failed to update Customer after ${maxAttempts} attempts.`);
                 }
                 resolve(success);
            })
        },
        async setAgreementDetailsToUserCollection(userId,emailId){
            return new Promise(async(resolve, reject) => {

                const setAgreementDetailsToUser = firebaseApp.functions('northamerica-northeast1').httpsCallable('publicApi-setAgreementDetailsToUser');
				let body={
					agreementId: this.agreementId,
					userId: userId,
                    emailId:emailId,
                    checkPendingInvites:true
				}
                // console.log('body',body)
				const agrDetailsSetting = await setAgreementDetailsToUser(body).catch((error) => {
                        console.error('Error getting documents: ', error);
                        return resolve()
                      });
                resolve(agrDetailsSetting)
                // await usersCollection.doc(userId).get().then(async (doc) => {
                // // console.log('doc.exists',doc.exists)
                // if (doc.exists) {
                //     // console.log('doc.data()',doc.data())
                //     const agreements = doc.data().agreements || [];
                //     const hasMatchingId = agreements.some(agreement => agreement.id === this.agreementId);
                //     // console.log('hasMatchingId',hasMatchingId)
                //         if (hasMatchingId) {
                //             // console.log('Document found:', {
                //             id: doc.id,
                //             data: doc.data(),
                //             });
                //             resolve();
                //         } else {
                //             // console.log('this.agreementId',this.agreementId)
                //             let singleAgreementDetails = await agreementsCollection.doc(this.agreementId).get().catch((error) => {
                //                                                     console.error('Error getting agreements: ', error);
                //                                                     reject(error)
                //                                                 })
                //             if(singleAgreementDetails?.data()){
                //                 const agreementData = singleAgreementDetails?.data();
                //                 // console.log('agreementData',agreementData)
                //                 const newDataToAdd = {
                //                     id: this.agreementId,
                //                     title: agreementData.title,
                //                     createdOn: agreementData.createdOn,
                //                     updatedAt: agreementData.updatedAt,
                //                 };
                //                 let userCollectionUpdate = await usersCollection.doc(userId).update({
                //                     agreements: [...agreements, newDataToAdd],
                //                 }).catch((error) => {
                //                     console.error('Error updating usersCollection: ', error);
                //                     reject(error)
                //                 })
                //                 // console.log('agreementData ---')
                //                 resolve();
                //             }
                //         }
                //     } else {
                //     // console.log('Document does not exist.');
                //     resolve();
                //     }
                // })
                // .catch((error) => {
                //     console.error('Error getting documents: ', error);
                //     reject(error)
                // });
            })
        },
        async getRoleLevelPerAgreementOfCustomer(){
            return new Promise(async(resolve, reject) => {
                let rolePerAgreement = '';
                if(this.agreementId){
                    let perm = await agreementsCollection
                                        .doc(this.agreementId)
                                        .collection("users")
                                        .doc("permissions")
                                        .get()
                                        .catch((err)=>{
                                            console.error(err);
                                            reject()
                                        });
                    // console.log('perm->',perm.data());
                    const permissionData = perm.data();
                    let permissionObjKeys = Object.keys(permissionData);
                    if(permissionData && permissionObjKeys?.length){
                        // console.log('1');
                        for(let i=0; i<permissionObjKeys.length ; i++){
                            // console.log('2',this.clientDetails?.email,permissionData[permissionObjKeys[i]].email);
                            if(this.clientDetails?.email && permissionData[permissionObjKeys[i]].email && this.clientDetails.email == permissionData[permissionObjKeys[i]].email){
                                rolePerAgreement = permissionData[permissionObjKeys[i]].role;
                            }
                        }
                    }
                }
                resolve(rolePerAgreement);
            })
        },
        async createAccount() {
            this.$v.clientDetails.$touch();
            if (this.$v.clientDetails.$anyError) {
                return;
            }
            try {
				this.signUpLoading = true;
                const userAlreadySignedUp = await this.getCustomerUsingEmail(this.clientDetails.email)
                if((this.authProvider == 'EMAIL' || this.authProvider == 'CLIO') && !this.emailDisabled){
                    this.userSnap = await this.createUserWithEmailAndPasswordQuery(
                        this.clientDetails.email,
                        this.clientDetails.password
                    );
                    // this.userSnap = await auth.signInWithEmailAndPassword(
                    //     this.clientDetails.email,
                    //     this.clientDetails.password
                    // );
                    // console.log('1')
                } else if(this.emailDisabled && this.clientDetails.email){
                    if(!this.isUserExists){
                        if(Object.keys(userAlreadySignedUp).length && userAlreadySignedUp.roleLevel){
                            this.userSnap = await this.signInWithEmailAndPasswordQuery(
                                this.clientDetails.email,
                                this.clientDetails.password
                            );
                        } else {
                            this.userSnap = await this.createUserWithEmailAndPasswordQuery(
                                this.clientDetails.email,
                                this.clientDetails.password
                            );
                        }
                    }
                    // console.log('2')
                }
///////////////////////////////////////
                await this.saveUserDataToCollection(userAlreadySignedUp);
                    // console.log('3')
                let routeParamsObj;
                if(localStorage.getItem('redirectRouteParams')){
					routeParamsObj = JSON.parse(localStorage.getItem('redirectRouteParams'));
                    routeParamsObj.page = 1;
                    routeParamsObj.auth = 0;
                    // this.$router.push({ name: 'auth' ,query :routeParamsObj});
                    this.$router.replace({query: routeParamsObj}).catch(() => {});
                    localStorage.removeItem('redirectRouteParams');
                } else {
                    this.$router.replace({query: {page:1,auth:0}}).catch(() => {});
                }
                this.$store.dispatch('logout');
                this.successMessage('Account Created','Please login using the credentials to access DIVII');
                this.signUpLoading = false;
                // let authProvider = this.authProvider ? this.authProvider.toUpperCase() : 'EMAIL';
				// this.$store.commit('setLoginProvider',authProvider);
				// this.$store.dispatch('login', this.userSnap.user).then(async () => {				
				// // console.log(`User ${this.userSnap.user.email} logged in`);
				// 	if(this.getClientOrPreviouslyLoggedId){
				// 			// console.log('==>>>')
				// 			await fb.customersCollection.doc(this.userSnap.user.uid).set({
				// 				isClientUser : true,
				// 			}, { merge: true }).catch((err)=>{
				// 				console.error('err***',err);
				// 				return ;//reject(err)
				// 			});
				// 			this.signUpLoading = false;
				// 			if(this.getPreviouslyLoggedClientUserAgreement)
				// 				localStorage.setItem('clientUserAgreement', this.getPreviouslyLoggedClientUserAgreement);
				// 			this.$router.push({
				// 				name: 'clientUserAgreement',
				// 				params: { id: this.getClientOrPreviouslyLoggedId },
				// 			});
				// 	} else {
				// 			this.signUpLoading = false;
				// 			this.$router.push({ name: 'home' });
				// 	}
                //     localStorage.removeItem('redirectRouteParams');
				// });

			}
             catch (error) {
				var errorCode = error.code;
				var errorMessage = error.message;
				console.error(errorCode, errorMessage);
                if (errorCode == 'auth/email-already-in-use') {
					this.signupError = errorMessage + '. Please login to access your account';
				} 
                else{
                    this.signupError = 'There was a problem setting up your account please try again.';
                }	
                this.signUpLoading = false;
                
			}
        },
        async signInWithClio() {
            await this.setAuthProvider('CLIO');
            if(this.$route.query)
                localStorage.setItem('redirectRouteParams',JSON.stringify(this.$route.query))
                clioLogin();
        },
        signInWithGoogle() {
            this.setAuthProvider('GOOGLE');
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: 'select_account'
            });
            this.isUserExists = false;
            auth.signInWithPopup(provider).then(async (googleResponse)=>{
                // console.log('google resp-->',googleResponse)
                this.userSnap = googleResponse;
                let userAlreadySignedUp = await this.getCustomerUsingEmail(this.userSnap.additionalUserInfo.profile.email)
                // console.log('googleResponse-->',googleResponse) 
                // console.log('userAlreadySignedUp-->',userAlreadySignedUp);
                if(this.userSnap.additionalUserInfo &&  this.userSnap.additionalUserInfo.profile)
                    localStorage.setItem('loggedInEmailID',this.userSnap.additionalUserInfo.profile.email);
                if(Object.keys(userAlreadySignedUp).length && userAlreadySignedUp.roleLevel){
                    this.isUserExists = true;
                    // console.log('before fetchRoleLevel')
                    let abc = await this.fetchRoleLevel(this.userSnap.additionalUserInfo.profile.email).catch((error)=>{
                        const errorMessage = error.message;
                        if(errorMessage === 'Role level mismatch'){
                            this.loginError = 'The role you selected and the role assigned to this user is different. Please reselect the role and continue';
                            this.errorMessage('',this.loginError)
                            this.$store.dispatch('logout');
                            this.changeloginPage(1)
                            return;
                        }
                    });
                    // console.log('abc',abc)

                    if(this.$route.query && this.$route.query.clioFetch == 'true'){
                        localStorage.setItem('redirectRouteParams',JSON.stringify(this.$route.query))
                        clioLogin();
                    } else {
                        this.$router.push({ name: 'home' }).catch(() => {});
                    }
                } else
                if(this.userSnap) {
                    this.isUserExists = true;
                    if(this.userSnap.additionalUserInfo &&  this.userSnap.additionalUserInfo.profile){
                        this.clientDetails.firstName =  this.userSnap.additionalUserInfo.profile.given_name;
                        this.clientDetails.lastName =  this.userSnap.additionalUserInfo.profile.family_name;
                    }
                    this.clientDetails.email =  this.userSnap.additionalUserInfo.profile.email;
                    this.emailDisabled = true;
                    this.activeTab = 1;
                    // await this.changeTab(1,1);
                    // this.changeSignUpPage(3);
                    this.$router.replace({
                        query: { ...this.$route.query, auth: 1, page:3 },
                    }).catch(() => {});
                }  
                
                if(this.agreementId){
                    // console.log('----')
                    await this.setAgreementDetailsToUserCollection(this.userSnap.user.uid,this.userSnap.user.email);
                }

            })
        },
        async signInWithEmail() {
            await this.setAuthProvider('EMAIL');
            this.changeloginPage(3)
        },
        async getCustomerUsingID(uid){
			const cust  = await customersCollection.doc(uid).get();
			// console.log('cust-->',cust.data())
			return cust && cust.data() ? cust.data() : {};
		},
        async getCustomerUsingEmail(email){
            return new Promise(async resolve => {
			const customers  = await customersCollection.where("email", "==", email).get();
            const data = customers.docs.map((doc) => doc.data());
            data && data[0] ? resolve(data[0]) : resolve({});
            });
            },
        fetchRoleLevel(email) {
            return new Promise((resolve, reject) => {
                customersCollection.where("email", "==", email).get()
                    .then((querySnapshot) => {
                        const data = querySnapshot.docs.map((doc) => doc.data());
                        // console.log('data==',data);
                        if(data?.length){
                        let fetchedRoleLevel = (data && data[0] && data[0].roleLevel) ? data[0].roleLevel : 'lawyer-level-1';
                        let foundRoleLevel = this.roleLevels.find(role => role.roleName == fetchedRoleLevel);
                        if(foundRoleLevel){
                            // console.log('currentRole->',this.currentRole)
                            // console.log('foundRoleLevel->',foundRoleLevel)
                            if (this.currentRole !== foundRoleLevel.roleAssociated){
                                this.$store.dispatch('clearConditionalStorageValues')
                                reject(new Error('Role level mismatch'));
                            }
                        }
                        resolve(fetchedRoleLevel);
                        } else {
                            resolve()
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        async login() {
            this.$v.loginDetails.$touch();
            if(this.$v.loginDetails.$anyError){
                return
            }
            this.loginLoading = true;
            try {

                await this.fetchRoleLevel(this.loginDetails.email);

                const userSnap = await this.signInWithEmailAndPasswordQuery(
					this.loginDetails.email,
					this.loginDetails.password
				);
				// this.$store.commit('setLoginProvider', 'EMAIL')
				this.$store.dispatch('login', userSnap.user).then(async() => {
					
					// console.log(`User ${userSnap.user.email} logged in`);
						let loggedInCustomer = await this.getCustomerUsingID(userSnap.user.uid);
						// console.log(`User abc--> ${JSON.stringify(loggedInCustomer)}`);		
						this.loginLoading = false;
                        localStorage.removeItem('redirectRouteParams');

                        
                        if(this.agreementId){
                            // console.log('----')
                            await this.setAgreementDetailsToUserCollection(userSnap.user.uid,userSnap.user.email);
                        }
						// if(this.getClientOrPreviouslyLoggedId && loggedInCustomer && loggedInCustomer.isClientUser){
						// 	if(this.getPreviouslyLoggedClientUserAgreement)
						// 		localStorage.setItem('clientUserAgreement', this.getPreviouslyLoggedClientUserAgreement);
						// 	this.$router.push({
						// 		name: 'clientUserAgreement',
						// 		params: { id: this.getClientOrPreviouslyLoggedId },
						// 	});
						// } else {
                            let loginProvider = localStorage.getItem('loginProvider');
                            if(this.$route.query && this.$route.query.clioFetch == 'true' && loginProvider != 'CLIO'){
                                    localStorage.setItem('redirectRouteParams',JSON.stringify(this.$route.query))
                                    clioLogin();
                            } else 
						        this.$router.push({ name: 'home' });
                        // }
					
				});
                
			}  catch (error) {
				// Handle Errors here.
				var errorCode = error.code;
				var errorMessage = error.message;
				console.error(errorCode, errorMessage);
                this.loginLoading = false;
				if (errorCode == 'auth/user-not-found') {
					this.loginError = 'User does not exist';
				} else if (
					errorCode == 'auth/wrong-password' ||
					errorCode == 'auth/invalid-email'
				) {
					this.loginError = 'Invalid username or password';
				} else if (errorCode == 'auth/user-disabled') {
					this.loginError = 'Account Disabled';
					this.forgotPassword = true;
				}
                else if(errorMessage === 'Role level mismatch'){
                    this.loginError = 'The role you selected and the role assigned to this user is different. Please reselect the role and continue';
                }
			}
        },
        forceLogout(){
            this.$store.dispatch('logout');
        },
        sendInstructions(){
            this.$v.resetEmail.$touch();
            if(this.$v.resetEmail.$anyError){
                return
            }
            this.resetPasswordError = null;
            this.resetPasswordLoading = true
            auth.sendPasswordResetEmail(this.resetEmail).then(() => {
                this.resetPasswordLoading = false;
                this.resetEmailSent = true;
                // console.log("Reset password link sent successfully")
            })
            .catch(error => {
                this.resetPasswordLoading = false;
                this.resetEmailSent = false;
                this.resetPasswordError = error.message;
                console.error("ERROR:", error.message)
            });
        },
        resetSignUpForm(){
            this.clientDetails = {
                firstName: '',
                lastName: '',
                organizationName: '',
                locationOfPractice: '',
                email: '',
                phoneNumber: '',
                password: '',
                confirmPassword: '',
            }
            this.$v.clientDetails.$reset()
            this.signupError = null
        },
        resetLoginForm(){
            this.$v.loginDetails.$reset()
            this.loginError = null
        },
        setQueryValuesToPage(query){
            // if role param exists in the given roles set param value, otherwise set 'lawyer' by default.
            this.currentRole = Object.keys(this.roles).includes(query.role) ? query.role : 'lawyer';
            this.activeTab = query.auth ? Number(query.auth) : 0
            this.connectClio = query.clioFetch || false;
            this.authProvider =  query.authProvider || '';
            this.agreementId = query.id || '';
            const page = query.page ? Number(query.page) : 1;
            this.isGoBackVisible = query.isGoBackVisible !== 'false';
            if(this.activeTab == 0){            
                this.$store.dispatch('setCurrentLoginPageAction',page); 
            } else {
                this.$store.dispatch('setCurrentSignUpPageAction',page); 
            }
        },
        signInWithEmailAndPasswordQuery(email,password){
            return new Promise(async (resolve,reject)=>{
                const userSnap = await auth.signInWithEmailAndPassword(email,password)
                                            .catch((err)=>{
                                                console.error(err);
                                                return reject(err)
                                            });
                resolve(userSnap);
            })
        },
        createUserWithEmailAndPasswordQuery(email,password){
            return new Promise(async (resolve,reject)=>{
                const userSnap = await auth.createUserWithEmailAndPassword(email,password)
                                           .catch((err)=>{
                                                console.error(err);
                                                return reject(err)
                                           });
                
                this.sendOnboardingEmail(email);    
				// await onboardingEmailRequest(body)

                resolve(userSnap);
            })
        },
        async sendOnboardingEmail(email){

                //const onboardingEmailRequest = firebaseApp.functions('northamerica-northeast1').httpsCallable('emailApi-sendOnboardingEmail');
				let body={
					toEmail: email,
                    host: window.location.host,
                    role: this.$route.query.role,
                    firstName: this.clientDetails.firstName,
                    logo: "http://cdn.mcauto-images-production.sendgrid.net/d700b1a78db738eb/639f4f4c-6298-49ac-865c-453e0861a165/473x207.png",
				}
                // console.log('body',body)
                const emailReq =  await emailApiRequest('POST','/onboardingEmail',body).catch((err)=>  console.log('onboardingEmail err->',err));
                // console.log('emailReq',emailReq)
        }
    },
    watch: {
        '$route.query': {
            immediate: true,
            handler(newQuery) {
                // console.log('newQuery',newQuery)
                if (newQuery)
                    this.setQueryValuesToPage(newQuery);
                this.forgotPassword = false;
            }
        },
        '$v.loginDetails.email.$model': {
            handler: function (value) {
                this.loginError = '';
            },
            deep: true,
    },
    },
};
</script>

<template>
    <b-container class="home-container mt-4 p-4">
        <b-row>
            <b-col class="d-none d-lg-block">
                <transitionGroup name="zoom-in">
                    <div v-if="(activeTab === 0 && !forgotPassword)" class="login-heading mt-5 text-center"
                        key="login-text-1">
                        Welcome back,
                    </div>
                    <div v-if="activeTab === 1" class="login-heading mt-5 text-center" key="signup-text-1">
                        Welcome!
                    </div>
                    <div v-if="forgotPassword" class="login-heading mt-5 text-center" key="signup-text-1">
                        Oops!
                    </div>
                </transitionGroup>
                <transitionGroup name="zoom-in">
                    <div v-if="(activeTab === 0 && !forgotPassword)" class="login-desc mt-4 text-center" key="desc-1">
                        Login to your account
                    </div>
                    <div v-if="activeTab === 1" class="login-desc mt-4 text-center" key="desc-2">
                        Sign up to get started.
                    </div>
                    <div v-if="forgotPassword" class="login-desc mt-4 text-center" key="desc-3">
                        Forgot Password?
                    </div>
                </transitionGroup>
                <transitionGroup name="zoom-in">
                    <div v-if="(activeTab === 0 && !forgotPassword)" class="text-center" key="login-img-1" data-testid='login-img-1'>
                        <img src="@/assets/login-img.png" alt="legal fair" width="400" class="pt-5" />
                    </div>
                    <div v-if="activeTab === 1" class="text-center" key="signup-img-1" data-testid='signup-img-1'>
                        <img src="@/assets/LegalFair.svg" alt="legal fair" width="400" class="pt-5" />
                    </div>
                    
                </transitionGroup>
                <transition name="slide-fade">
                    <div v-if="forgotPassword" class="text-center" key="forgot-img-1" data-testid='signup-img-1'>
                        <img src="@/assets/forgotPassword.svg" alt="forgot password" class="">
                    </div>
                </transition>
            </b-col>
            <b-col class="login-signup-form">
                <b-tabs pills fill class="login-tabs mt-2" data-testid='login-tabs' v-model="activeTab">
                    <b-tab title="Log in" id="login-tab" @click="changeTab($event,0)">
                        <transition name="zoom-in" data-testid="login-page">
                            <div v-if="activeTab === 0" data-testid="login-page">

                                <transitionGroup name="slide-fade">
                                    <div v-if="currentLoginPage === 1" key="login-1" class="page-slide page-slide-arrange-login" data-testid="login-page">
                                        <div class="text-center mt-4 signup-text-1">
                                            First, select your role.
                                        </div>
                                        <b-row class="mt-4 flex-nowrap">
                                            <b-col @click="changeRole('lawyer')" class="text-center clickable" data-testid="role-lawyer">
                                                <img src="@/assets/lawyer.png" alt="lawyer" width="90" class="role-img"
                                                    :class="{
                                                        'active-role':
                                                            currentRole === 'lawyer',
                                                    }" />
                                                <p class="pt-2" varient="active" :class="{
                                                    'active-role-name':
                                                        currentRole === 'lawyer',
                                                }">
                                                    Lawyer
                                                </p>
                                            </b-col>
                                            <b-col @click="changeRole('party')" class="text-center clickable"  data-testid="role-party">
                                                <img src="@/assets/separating-party.png" alt="separating party" width="90"
                                                    class="role-img" :class="{
                                                        'active-role':
                                                            currentRole === 'party',
                                                    }" />
                                                <p :class="{
                                                    'active-role-name':
                                                        currentRole === 'party',
                                                }">
                                                    Separating party
                                                </p>
                                            </b-col>
                                            <!-- <b-col @click="changeRole('assistant')" class="text-center clickable"  data-testid="role-assistant">
                                                <img src="@/assets/assistant.png" alt="assistant" width="90"
                                                    class="role-img" :class="{
                                                        'active-role':
                                                            currentRole === 'assistant',
                                                    }" />
                                                <p :class="{
                                                    'active-role-name':
                                                        currentRole === 'assistant',
                                                }">
                                                    Assistant
                                                </p>
                                            </b-col> -->
                                        </b-row>
                                        <div class="p-sm-3 pt-sm-0">
                                            <template v-if="currentRole === 'lawyer' || currentRole === 'assistant'" >
                                            <div class="signup-text-2">
                                                Do you want us to connect to your Clio
                                                account so that you have access to your
                                                current file details
                                            </div>
                                            <div class="signup-text-3 mt-1">
                                                This will need additional login to CLIO
                                            </div>
                                            <div class="d-flex mt-3 justify-content-sm-start">
                                                <div class="signup-text-4 pr-2 pt-1">
                                                    No
                                                </div>
                                                <b-form-checkbox class="clio-checkbox clickable" switch v-model="connectClio" @change="handleClioCheckbox"
                                                    size="lg"  data-testid="clio-import-checkbox"></b-form-checkbox>
                                                <div class="signup-text-4 pt-1">Yes</div>
                                            </div>
                                            </template>
                                        </div>
                                        <!-- <div v-if="currentRole !== 'lawyer'" class="empty-div"></div> -->
                                        <div>
                                            <b-button @click="changeloginPage(2)" variant="primary" pill
                                                class="proceed-login-btn login-page-btn-blue col-12 signup-text-1"
                                                size="lg"  data-testid="proceed-to-login">Proceed to Login
                                            </b-button>
                                        </div>

                                    </div>
                                    <div v-if="currentLoginPage === 2" class="text-center page-slide page-slide-2" key="login-2">
                                        <b-row class="mt-5 flex-nowrap">
                                            <b-col class="text-center role-ht" :data-testid="`signup-role-${currentRole}`">
                                                <img :src="`${roles[`${(currentRole)}`].icon}`" :alt="`${roles[`${(currentRole)}`].key}`" width="90" class="active-role"/>
                                                <p class="pt-2 active-role-name" varient="active">
                                                    {{ roles[`${(currentRole)}`].label }}
                                                </p>
                                            </b-col>
                                        </b-row>
                                        <div class="d-flex flex-column justify-content-center">
                                            <div>
                                                <b-button @click="signInWithClio" variant="header-white" pill
                                                    class="col-9 signup-text-2 auth-provider-btn mt-5"
                                                    v-if="currentRole !== 'party'" size="lg"  data-testid="clio-sign-in"><img
                                                        src="@/assets/clioLogo.svg" alt="" />&nbsp;Sign in with CLIO
                                                </b-button>
                                                <div v-else class="mt-5 empty-button"></div>
                                            </div>
                                            <div>
                                                <b-button @click="signInWithGoogle" variant="header-white" pill
                                                    class="col-9 signup-text-2 auth-provider-btn mt-3" size="lg"  data-testid="google-sign-in"><img
                                                        src="@/assets/googleLogo.svg" alt="" />&nbsp;Sign in with Google
                                                </b-button>
                                            </div>
                                            <div>
                                                <b-button @click="signInWithEmail" variant="header-white" pill
                                                    class="col-9 signup-text-2 auth-provider-btn mt-3"
                                                    size="lg"  data-testid="email-sign-in"><b-icon-envelope />&nbsp;Sign in with Email
                                                </b-button>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-end">
                                            <div @click="changeloginPage(1)" class="clickable signup-text-5 text-left mt-5" data-testid="login-go-back-1" v-if="isGoBackVisible">
                                                <b-icon-arrow-left scale="1.5" /> &nbsp;Go Back
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="currentLoginPage === 3 && forgotPassword === false" key="login-3"
                                        class="page-slide">
                                        <div class="text-center mt-4">
                                            <img src="@/assets/lawyer.png" alt="lawyer" v-if="currentRole === 'lawyer'"
                                                width="100" :class="{
                                                    'active-role': currentRole === 'lawyer',
                                                }" />
                                                <p v-if="currentRole === 'lawyer'" class="pt-2 active-role-name mb-0" varient="active">
                                                    Lawyer
                                                </p>
                                            <img src="@/assets/separating-party.png" alt="separating party"
                                                v-if="currentRole === 'party'" width="100" :class="{
                                                    'active-role': currentRole === 'party',
                                                }" />
                                                <p class="pt-2 active-role-name  mb-0" varient="active"
                                                v-if="currentRole === 'party'">
                                                    Separating party
                                                </p>
                                            <img src="@/assets/assistant.png" alt="assistant"
                                                v-if="currentRole === 'assistant'" width="100" :class="{
                                                    'active-role':
                                                        currentRole === 'assistant',
                                                }" />
                                                <p class="pt-2 active-role-name  mb-0" varient="active"
                                                v-if="currentRole === 'assistant'">
                                                    Assistant
                                                </p>
                                        </div>
                                        <b-form-group>
                                            <b-form-group label="Email address" class="input-required"
                                            :class="{'login-input-margin': !$v.loginDetails.email.$error}">
                                                <b-form-input v-model="$v.loginDetails.email.$model"
                                                    :state="validateState('email', 'login')" class="login-input" autocomplete="username" :disabled="emailDisabled"
                                                    data-testid="login-email-input">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.loginDetails.email.required">
                                                    Email is required
                                                </b-form-invalid-feedback>

                                                <b-form-invalid-feedback v-if="!$v.loginDetails.email.email">
                                                    This must be a valid Email address.
                                                </b-form-invalid-feedback>
                                            </b-form-group>

                                            <b-form-group label="Password"
                                                class="mt-2 input-required"
                                                :class="{'login-input-margin': !$v.loginDetails.password.$error}">
                                                <b-input-group>
                                                    <b-form-input v-model="$v.loginDetails.password.$model"
                                                        :state="validateState('password', 'login')" class="login-input"  :type="showPassword ? 'text' : 'password'"
                                                        autocomplete="current-password"
                                                        data-testid="login-password-input"
                                                        @keydown.enter="login">
                                                    </b-form-input>
                                                    <b-input-group-append>
                                                        <div @click="showPassword = !showPassword"
                                                            class="input-group-text show-password-btn clickable">
                                                            <b-icon-eye v-if="! showPassword"/>
                                                            <b-icon-eye-fill v-if="showPassword"/>
                                                        </div>
                                                    </b-input-group-append>
                                                </b-input-group> 
                                                <div v-if="$v.loginDetails.password.$error" class="invalid-feedback d-block">
                                                    Password is required
                                                </div> 
                                            </b-form-group>
                                        </b-form-group>
                                        <div class="d-flex justify-content-between">
                                            <div @click="changeloginPage(2);resetLoginForm()" class="clickable signup-text-5 pt-2"  data-testid="login-go-back-2">
                                                <b-icon-arrow-left scale="1.5" /> &nbsp;Go Back
                                            </div>
                                            <div class="col-sm-5 col-6 px-0">
                                                <b-button variant="primary" pill
                                                    class="login-page-btn-blue col-12 signup-text-1"
                                                    :disabled="$v.loginDetails.$anyError || loginLoading" @click="login" size="lg"  data-testid="login-btn">
                                                    <b-spinner v-if="loginLoading" small></b-spinner>
                                                    <span v-else>Login</span>
                                                </b-button>
                                            </div>

                                        </div>
                                        <div v-if="loginError" class="text-center invalid-feedback d-block pt-2">{{ loginError }}</div>
                                        <div class="text-center mt-2 pt-1 clickable"  @click="forgotPassword = true"  data-testid="forgot-password-btn">Forgot Password ? <span
                                                class=" font-weight-bold">Click
                                                here</span></div>
                                    </div>

                                    <div v-if="forgotPassword === true" key="login-4" class="page-slide page-slide-2">
                                        <div class="forgot-password-form">
                                            <b-form-group label="Email address" class="mt-5">
                                                <b-form-input class="login-input" :state="validateState('', 'forgotPassword')" v-model="$v.resetEmail.$model"
                                                    data-testid="forgot-password-email"
                                                    @keydown.enter="sendInstructions">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.resetEmail.required">
                                                    Email is required
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="!$v.resetEmail.email">
                                                    This must be a valid Email address.
                                                </b-form-invalid-feedback>
                                                <p class="forot-password-desc-2">If we recognize your email address, we send you
                                                    an email with instructions on resetting your password.</p>
                                                <div v-if="resetEmailSent" class="success-msg">Reset link has been sent to the mail <b>{{resetEmail}} </b>
                                                .If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder.</div>
                                                <div v-else class="error-msg">{{ resetPasswordError }}</div>
                                            </b-form-group>
                                        </div>
                                        
                                        <div class="d-flex justify-content-between mb-4 align-items-end">
                                            <div @click="forgotPassword = false"
                                                class="clickable signup-text-5 float-left mb-2"
                                                data-testid="forgot-password-go-back">
                                                <b-icon-arrow-left scale="1.5" /> &nbsp;Go Back
                                            </div>
                                            <div class="col-sm-5 col-7 px-0">
                                                <b-button variant="primary" pill
                                                    @click="sendInstructions"
                                                    :disabled="$v.resetEmail.$anyError || resetPasswordLoading"
                                                    class="login-page-btn-blue col-12 text-nowrap  signup-text-1"
                                                    size="lg"
                                                    data-testid="send-instructions">
                                                    <div v-if="resetPasswordLoading">
                                                      <b-spinner small type="grow">
                                                       
                                                    </b-spinner>   Sending...
                                                    </div>
                                                    
                                                    <span v-else-if="!resetPasswordLoading  && !resetEmailSent">Send instructions</span>
                                                    <span v-else-if="!resetPasswordLoading && resetEmailSent">Resend Instructions</span>
                                                </b-button>
                                            </div>
                                            
                                        </div>

                                    </div>
                                </transitionGroup>

                                <div class="col pagination-dots">
                                    <div v-for="page in pages" :key="page" @click="changeDotPage(page,'login')"
                                        class="ml-2 clickable"  data-testid="login-page-nav">
                                        <span
                                         :class="page > highestLoginPageVisited ? 'page-dot-disabled' : (currentLoginPage === page ? 'page-dot-active' : 'page-dot')"
                                         >●
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </transition>
                    </b-tab>

                    <b-tab title="Sign up" id="sign-up-tab" @click="changeTab($event,1)">
                        <transition name="zoom-in">
                            <div v-if="activeTab === 1">
                                <transitionGroup name="slide-fade">
                                    <div v-if="currentSignUpPage === 1" key="signup-1" class="page-slide page-slide-arrange" data-testid="signup-1">
                                        <div class="text-center mt-4 signup-text-1">
                                            First, select your role.
                                        </div>
                                        <b-row class="mt-4 flex-nowrap">
                                            <b-col @click="changeRole('lawyer')" class="text-center clickable role-ht" data-testid="signup-role-lawyer">
                                                <img src="@/assets/lawyer.png" alt="lawyer" width="90" class="role-img"
                                                    :class="{
                                                        'active-role':
                                                            currentRole === 'lawyer',
                                                    }" />
                                                <p class="pt-2" varient="active" :class="{
                                                    'active-role-name':
                                                        currentRole === 'lawyer',
                                                }">
                                                    Lawyer
                                                </p>
                                            </b-col>
                                            <b-col @click="changeRole('party')" class="text-center clickable role-ht" data-testid="signup-role-party">
                                                <img src="@/assets/separating-party.png" alt="separating party" width="90"
                                                    class="role-img" :class="{
                                                        'active-role':
                                                            currentRole === 'party',
                                                    }" />
                                                <p :class="{
                                                    'active-role-name':
                                                        currentRole === 'party',
                                                }">
                                                    Separating party
                                                </p>
                                            </b-col>
                                            <!-- <b-col @click="changeRole('assistant')" class="text-center clickable role-ht" data-testid="signup-role-assistant">
                                                <img src="@/assets/assistant.png" alt="assistant" width="90"
                                                    class="role-img" :class="{
                                                        'active-role':
                                                            currentRole === 'assistant',
                                                    }" />
                                                <p :class="{
                                                    'active-role-name':
                                                        currentRole === 'assistant',
                                                }">
                                                    Assistant
                                                </p>
                                            </b-col> -->
                                        </b-row>
                                        <!-- <div v-if="currentRole === 'lawyer'" class="p-sm-5 pt-0 empty-div">
                                            <div class="signup-text-2">
                                                Do you want us to connect to your Clio
                                                account so that you have access to your
                                                current file details
                                            </div>
                                            <div class="signup-text-3 mt-1">
                                                This will need additional login to CLIO
                                            </div>
                                            <div class="d-flex mt-3 justify-content-center justify-content-sm-start">
                                                <div class="signup-text-4 pr-2 pt-1">
                                                    No
                                                </div>
                                                <b-form-checkbox class="clio-checkbox" switch v-model="connectClio" @change="handleClioCheckbox"
                                                    size="lg" data-testid="clio-import-signup-checkbox"></b-form-checkbox>
                                                <div class="signup-text-4 pt-1">Yes</div>
                                            </div>
                                        </div> -->
                                        <!-- <div v-if="currentRole !== 'lawyer'" class="empty-div"></div> -->
                                        <div>
                                            <b-button @click="changeSignUpPage(2)" variant="primary" pill
                                                class="login-page-btn-blue col-12 signup-text-1" size="lg" data-testid="proceed-to-signup">Proceed to Sign
                                                up
                                            </b-button>
                                        </div>
                                    </div>

                                    <div v-if="currentSignUpPage === 2" class="text-center page-slide page-slide-2 page-slide-arrange" key="signup-2">
                                        <div class="text-center mt-4 signup-text-1">
                                            Please select your mode of authentication.
                                        </div>
                                        <b-row class="mt-5 flex-nowrap">
                                            <b-col class="text-center role-ht" :data-testid="`signup-role-${currentRole}`">
                                                <img :src="`${roles[`${(currentRole)}`].icon}`" :alt="`${roles[`${(currentRole)}`].key}`" width="90" class="active-role"/>
                                                <p class="pt-2 active-role-name" varient="active">
                                                    {{ roles[`${(currentRole)}`].label }}
                                                </p>
                                            </b-col>
                                        </b-row>
                                        <div class="d-flex flex-column justify-content-center">
                                            <div>
                                                <b-button @click="signUpWithClio" v-if="currentRole !== 'party'"
                                                    variant="header-white" pill
                                                    class="col-9 signup-text-2 auth-provider-btn mt-5" size="lg"
                                                    data-testid="clio-signup-btn"><img
                                                        src="@/assets/clioLogo.svg" alt="" />&nbsp;Sign up with CLIO
                                                </b-button>
                                                <div v-else class="mt-5 empty-button"></div>
                                            </div>
                                            <div>
                                                <b-button @click="signUpWithGoogle" variant="header-white" pill
                                                    class="col-9 signup-text-2 auth-provider-btn mt-3" size="lg" data-testid="google-signup"><img
                                                        src="@/assets/googleLogo.svg" alt="" />&nbsp;Sign up with Google
                                                </b-button>
                                            </div>
                                            <div>
                                                <b-button @click="signUpWithEmail" variant="header-white" pill
                                                    class="col-9 signup-text-2 auth-provider-btn mt-3"
                                                    size="lg" data-testid="email-signup"><b-icon-envelope />&nbsp;Sign up with Email
                                                </b-button>
                                            </div>
                                        </div>
                                        <!-- <div class="space-filler"></div> -->
                                        <!-- <div class="space-filler"></div> -->
                                        <!-- <div class="text-left signup-text-4 mt-5">
                                Opting for any of the methods mentioned above will
                                prompt users to provide additional information in the subsequent step.
                                This ensures a personalized and secure experience tailored to your preferences.
                            </div> -->
                                        <div class="d-flex align-items-end">
                                            <div @click="changeSignUpPage(1)"
                                                class="clickable signup-text-5 text-left mt-5"
                                                data-testid="signup-go-back-1" v-if="isGoBackVisible">
                                                <b-icon-arrow-left scale="1.5" /> &nbsp;Go Back
                                            </div>
                                        </div>

                                    </div>

                                    <div v-if="currentSignUpPage === 3" key="signup-3" class="page-slide-2 pt-2">
                                        <!-- <div></div> -->
                                        <form>
                                            <div class="d-flex justify-content-between">
                                                <b-form-group label="First name" class="col-sm-6 pl-0 input-required"
                                                    invalid-feedback="First name is required"
                                                    :class="{'signup-input-margin': !$v.clientDetails.firstName.$error}">
                                                    <b-form-input v-model="$v.clientDetails.firstName
                                                        .$model
                                                        " :state="validateState('firstName')" class="signup-input"
                                                        data-testid="signup-first-name-input">
                                                    </b-form-input>
                                                </b-form-group>
                                                <b-form-group label="Last name" class="col-sm-6 pl-0 pr-0 input-required"
                                                    invalid-feedback="Last name is required"
                                                    :class="{'signup-input-margin': !$v.clientDetails.lastName.$error}">
                                                    <b-form-input v-model="$v.clientDetails.lastName.$model
                                                        " :state="validateState('lastName')" class="signup-input"
                                                        data-testid="signup-last-name-input">
                                                    </b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <b-form-group label="Organization Name" class="col-sm-6 pl-0" v-if="currentRole !== 'party'">
                                                    <b-form-input v-model="clientDetails.organizationName" class="signup-input"
                                                    data-testid="signup-org-name-input">
                                                    </b-form-input>
                                                </b-form-group>
                                                <b-form-group label="Province of agreement" class="col-sm-6 pl-0 pr-0"
                                                    :class="{'col-sm-12': currentRole === 'party'}"

                                                    label-class="text-nowrap">
                                                    <b-form-select
                                                        :options="Provinces"
                                                        text-field="name"
                                                        value-field="name"
                                                        v-model="clientDetails.locationOfPractice"
                                                        class="signup-input"
                                                        data-testid="signup-province-input"
                                                    >
                                                    </b-form-select>
                                                </b-form-group>
                                            </div>
                                            <b-form-group label="Phone number" invalid-feedback="Phone number is required" class="input-required"
                                            :class="{'signup-input-margin': !$v.clientDetails.phoneNumber.$error}">
                                                <b-form-input v-model="$v.clientDetails.phoneNumber.$model"
                                                    :state="validateState('phoneNumber')" class="signup-input" maxLength="10"
                                                    data-testid="signup-phone-input">
                                                </b-form-input>
                                            </b-form-group>
                                            <b-form-group label="Email address" class="input-required"
                                                :class="{'signup-input-margin': !$v.clientDetails.email.$error}">
                                                <b-form-input v-model="$v.clientDetails.email.$model"
                                                    :state="validateState('email')" autocomplete="username"
                                                    class="signup-input"
                                                    :disabled="emailDisabled"
                                                    data-testid="signup-email-input">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.clientDetails.email.required">
                                                    Email is required
                                                </b-form-invalid-feedback>

                                                <b-form-invalid-feedback v-if="!$v.clientDetails.email.email">
                                                    This must be a valid Email address.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <div class="d-sm-flex justify-content-between" v-if="authProvider != 'GOOGLE'">
                                                <b-form-group label="Password" class="col-sm-6 pl-0 input-required form-password"
                                                :class="{'signup-input-margin': !$v.clientDetails.password.$error}">
                                                    <b-input-group>
                                                        <b-form-input v-model="$v.clientDetails.password.$model
                                                            " :state="validateState('password')" :type="showPassword ? 'text' : 'password'" class="signup-input"
                                                            autocomplete="current-password"
                                                            data-testid="signup-password-input">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <div @click="showPassword = !showPassword"
                                                                class="input-group-text show-password-btn clickable">
                                                                <b-icon-eye v-if="! showPassword"/>
                                                                <b-icon-eye-fill v-if="showPassword"/>
                                                            </div>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                   
                                                    <div class="invalid-feedback d-block" v-if="!$v.clientDetails.password.required && $v.clientDetails.password.$error">
                                                        Password is required
                                                    </div>
                                                    <div class="invalid-feedback d-block" v-if="!$v.clientDetails.password.minLength && $v.clientDetails.password.$error">
                                                        Password must be at least 6 characters
                                                    </div>
                                                </b-form-group>
                                                <b-form-group label="Confirm Password" class="col-sm-6 pl-0 pr-0 input-required"
                                                :class="{'signup-input-margin': !$v.clientDetails.confirmPassword.$error}">
                                                    <b-input-group>
                                                        <b-form-input v-model="$v.clientDetails.confirmPassword
                                                            .$model
                                                            " :state="validateState('confirmPassword')"
                                                            :type="showConfirmPassword ? 'text' : 'password'" 
                                                            autocomplete="current-password" class="signup-input"
                                                            data-testid="signup-confirm-password-input"
                                                            @keydown.enter="createAccount">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <div @click="showConfirmPassword = !showConfirmPassword"
                                                                class="input-group-text show-password-btn clickable">
                                                                <b-icon-eye v-if="! showConfirmPassword"/>
                                                                <b-icon-eye-fill v-if="showConfirmPassword"/>
                                                            </div>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <div class="invalid-feedback d-block"
                                                        v-if="!$v.clientDetails.confirmPassword.required && $v.clientDetails.confirmPassword.$error">
                                                        Confirm Password is required
                                                    </div>

                                                    <div class="invalid-feedback d-block"
                                                        v-else-if="!$v.clientDetails.confirmPassword.sameAsPassword && $v.clientDetails.confirmPassword.$error">
                                                        Passwords must match
                                                    </div>
                                                </b-form-group>
                                            </div>
                                        </form>
                                        <div>
                                            <div @click="changeSignUpPage(2);forceLogout();resetSignUpForm()"
                                                class="clickable signup-text-5 float-left pt-2" data-testid="signup-go-back-2">
                                                <b-icon-arrow-left scale="1.5" /> &nbsp;Go Back
                                            </div>
                                            <b-button variant="primary" pill
                                                class="login-page-btn-blue col-sm-5 col-6 signup-text-1 text-nowrap float-right create-account-text"
                                                @click="createAccount" :disabled="$v.clientDetails.$anyError || signUpLoading"
                                                size="lg"
                                                data-testid="create-account">
                                                <b-spinner v-if="signUpLoading" small></b-spinner>
                                                <span v-else>
                                                    <span v-if="isUserExists" class="update-user-btn-text">Update User & Continue</span>
                                                    <template v-else>Create Account</template>
                                                </span>
                                            </b-button>
                                        </div>
                                        <div v-if="signupError" class="text-center invalid-feedback d-block">{{ signupError }}</div>
                                    </div>

                                    <div class="col pagination-dots" key="signup-4">
                                        <div v-for="page in pages" :key="page" @click="changeDotPage(page,'signup')"
                                            class="ml-2 clickable" data-testid="signup-page-nav">
                                            <span
                                                :class="page > highestSignupPageVisited ? 'page-dot-disabled' : (currentSignUpPage === page ? 'page-dot-active' : 'page-dot')"
                                                >●
                                        </span>
                                        </div>
                                    </div>
                                </transitionGroup>
                            </div>
                        </transition>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </b-container>
</template>

<style>

.zoom-in-enter-active{
	-webkit-animation: zoom-in-enter-active 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: zoom-in-enter-active 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes zoom-in-enter-active {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes zoom-in-enter-active {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.nav-link.active {
  opacity: 1 !important;
  transition: .7s ease-out !important;
}


.signup-input-margin{
    margin-bottom: 1.56rem !important;
}

.login-input-margin{
    margin-bottom: 1.8rem !important;
}

/* .zoom-in-enter-active {
    transition: all 0.6s ease;
    opacity: 1;

} */

.slide-fade-enter-active {
    transition: all 0.6s ease;
    opacity: 1;

}

.slide-fade-enter, .slide-fade-leave-active {
    position: absolute;
    opacity: 0;
}

.slide-fade-enter {
    transform: translateX(20%);
    opacity: 0;
}

.home-container {
    position: relative;
    border: 3px solid #cce1ea;
    border-radius: 8px;
    height: 85vh;
    width: 96vw;
}

.login-heading {
    font-weight: 700;
    font-size: 30px;
}

.login-desc {
    font-size: 20px;
    font-weight: 400;
}

.signup-text-1 {
    font-size: 20px;
    font-weight: 500;
}

.signup-text-2 {
    font-size: 17px;
    font-weight: 500;
}

.signup-text-3 {
    font-size: 15px;
    font-weight: 495;
}

.signup-text-4 {
    font-size: 15px;
    font-weight: 495;
}

.login-tabs .nav-link {
    border-radius: 20px !important;
}

.login-tabs .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #3d7190 !important;
}

.login-tabs ul {
    border-radius: 22px !important;
    border: 2px solid #3d7190;
}

.page-dot-active {
    color: #3d7190;
    font-size: 1.5rem;
}

.page-dot {
    color: #cce1ea;
    font-size: 1.5rem;
}

.page-dot-disabled {
    color: grey;
    font-size: 1.5rem;
    cursor:default ;
}

.login-signup-form {
    display: grid;
}

.pagination-dots {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    align-self: end;
}

.signup-text-5 {
    font-size: 18px;
    font-weight: 500;
    color: #3d7190;
}

.signup-input {
    border-radius: 10px;
}

.empty-div {
    height: 217px;
}

/* .page-slide {
    height: 453px;
} */

.form-group legend {
    padding-bottom: calc(0.1rem + 1px);
}

.active-role {
    filter: drop-shadow(0px 0px 25px #3d7190);
    zoom: 105%;
}

.active-role-name {
    font-weight: bold;
    color: #5b9ebb;
    font-size: large;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #dc3545 !important;
}

.invalid-feedback {
    color: #dc3545 !important;
    font-size: 0.875em !important;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 71, 78, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(218, 71, 78, 0.25);
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" stroke="%23dc3545" viewBox="0 0 12 12"><circle cx="6" cy="6" r="4.5"/><path stroke-linejoin="round" d="M5.8 3.6h.4L6 6.5z"/><circle cx="6" cy="8.2" r=".6" fill="%23dc3545" stroke="none"/></svg>') !important;
}

.signup-input {
    /* height: calc(1.5em + 0.75rem + 2px); */
    height: calc(1.2em + 0.75rem + 2px)
}

/* .signup-input.is-invalid {
    height: calc(1.2em + 0.75rem + 2px) !important;
} */

.login-input {
    border-radius: 10px;
    /* height: calc(2em + 0.75rem + 2px); */
    height: calc(1.5em + 0.75rem + 2px) !important;
}

/* .login-input.is-invalid {
    height: calc(1.5em + 0.75rem + 2px);
} */

.login-page-btn-blue {
    background-color: #3d7190;
    border-color: #3d7190 !important;
}

.forot-password-desc-2 {
    font-weight: 300;
    font-size: 15px;
}

.space-filler {
    height: 93px;
}

.empty-button {
    height: 44.55px;
}

.success-msg{
	padding-top: 10px;
	color: green;
}
.error-msg{
	padding-top: 10px;
	color: #dc3545;
}
@media (max-width: 576px) {
    .home-container {
        /* height: 87vh; */
        height: 100%;
    }

    .role-img {
        width: 70px;
    }

    .space-filler {
        height: 150px !important;
    }
    .create-account-text {
        font-size: 1.1rem !important;
    }
    .page-slide {
        height: 70vh !important;
        display: grid;
    }
    .forgot-password-form{
        display: grid;
        align-items: center !important;
    }
    .page-slide-2 {
        height: 100% !important;
    }
    .form-password{
        padding-right:0;
    }
}
.page-slide {
    height: 67vh !important;
    display: grid;
}

.page-slide-arrange{
    grid-template-rows: 2rem auto 3rem;
}
.page-slide-arrange-login{
    grid-template-rows: 4rem 10rem auto 3rem;
}
.page-slide-2 {
    height: 66vh;
    display: grid;
}
.clio-checkbox>label{
    cursor: pointer !important;
}

.show-password-btn{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.update-user-btn-text{
    font-size: 0.75rem;
    display: table-caption;
}

.tab-content >.active{
   background-color: #fff ; 
}

.input-required legend::after{
    content: " *" !important;
    color: red !important;
}

.auth-provider-btn{
    border: 1px solid #000;
}
.auth-provider-btn:hover{
    cursor: pointer;
    border: 1px solid #000;
    font-weight: bold;
}

.role-img:hover{
    scale: 1.1;
}
.role-img:hover + p{
    font-weight: bold;
}
.role-ht{
    max-height:30% ;
}
</style>
